import React from "react";
import {
  FaFacebookF,
  FaXTwitter,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <h3 className="footer-title">Kalority</h3>
        <p> Copyright 2019 &copy; Kalority.</p>
      </div>
      <div className="footer-center">
        <p>
          357 Mubea Road,
          <br />
          17113-00100, Nairobi
          <br />
          Kenya
        </p>
        <p>
          <strong>Get in touch</strong>
          <br />
          info@kalority.com
          <br />
          +254 (0) 743209690
        </p>
      </div>
      <div className="footer-right">
        <ul className="footer-links">
          <li>Documents</li>
          <li>Additional Info</li>
          <li>Application</li>
          <li>Useful Link</li>
        </ul>
        <div className="footer-icons">
          <a href="#">
            <FaFacebookF />
          </a>
          <a href="#">
            <FaXTwitter />
          </a>
          <a href="#">
            <FaYoutube />
          </a>
          <a href="#">
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
