// ContactPage.js
import React from "react";
import Footer from "./Footer";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="contact-container">
        <h2>
          <span className="bold-text">Get</span> in Touch
        </h2>
        <form className="contact-form">
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Full name"
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
          />
          <textarea
            id="message"
            name="message"
            rows="4"
            placeholder="Message"
          ></textarea>
          <button type="submit" className="submit-button">
            Send Message
          </button>
        </form>
        <div className="contact-info">
          <p>
            <span className="contact-highlight">Have any</span>{" "}
            <span className="contact-info-questions">Questions?</span>
          </p>
          <p>
            <span className="contact-info-number">+254 743209690</span>
          </p>
        </div>
        <div className="privacy-policy-wrapper">
          <h3 className="contact-highlight">Privacy Policy</h3>
          <p className="privacy-policy">
            SAVIX is built with privacy and security in mind. We don’t collect
            personal or financial data from users, and no information is stored
            on our servers. All data is stored locally on your device, with no
            automatic syncing to external servers. If you choose to back up your
            data, it will be securely sent to your personal email. Your
            information remains private, protected, and is never shared with any
            third parties. You are always in control of your data while using
            SAVIX, ensuring your financial information remains confidential.
          </p>
        </div>
      </div>
      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default ContactPage;
