import React, { useState, useEffect } from "react";
import "../App.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleMenuToggle = () => {
    if (isMobile || window.innerWidth <= 768) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  // Detect if the user is on a mobile device
  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Check for mobile devices (Android, iPhone, iPad, iPod, etc.)
      if (
        /android/i.test(userAgent) ||
        (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    const handleResize = () => {
      checkMobile();
      if (!isMobile && window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    checkMobile();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="#home" className="company-name navbar-text">
          <img
            src="./images/300x300 Logo.png"
            alt="Kalority Logo"
            className="navbar-logo"
          />
          KALORITY
        </a>
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a href="#home" className="nav-link navbar-text">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="#services" className="nav-link navbar-text">
            Services
          </a>
        </li>
        <li className="nav-item">
          <a href="#features" className="nav-link navbar-text">
            Features
          </a>
        </li>
        <li className="nav-item">
          <a href="#our-projects" className="nav-link navbar-text">
            Our Projects
          </a>
        </li>
        <li className="nav-item">
          <a href="#testimonials" className="nav-link navbar-text">
            Testimonials
          </a>
        </li>
        <li className="nav-item">
          <a href="#contact" className="nav-link navbar-text">
            Contact
          </a>
        </li>
        <li className="nav-item">
          <div className="hamburger-menu" onClick={handleMenuToggle}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className={`dropdown-menu ${isMenuOpen ? "show" : ""}`}>
            <a href="#home" className="dropdown-item">
              Home
            </a>
            <a href="#services" className="dropdown-item">
              Services
            </a>
            <a href="#features" className="dropdown-item">
              Features
            </a>
            <a href="#our-projects" className="dropdown-item">
              Our Projects
            </a>
            <a href="#testimonials" className="dropdown-item">
              Testimonials
            </a>
            <a href="#contact" className="dropdown-item">
              Contact
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
